import type { TypeDataSourceParsed } from "~/types/TypeDataSourceParsed";
import type {
  TypeApBreakdown,
  TypeApCompany,
  TypeApMethodology,
} from "~/types/csv/TypeAp";
import type {
  TypeAsBreakdown,
  TypeAsCompany,
  TypeAsMethodology,
} from "~/types/csv/TypeAs";
import type {
  TypeMsBreakdown,
  TypeMsCompany,
  TypeMsMethodology,
} from "~/types/csv/TypeMs";
import type {
  TypePdBreakdown,
  TypePdCompany,
  TypePdMethodology,
} from "~/types/csv/TypePd";
import type {
  TypePpiBreakdown,
  TypePpiCompany,
  TypePpiMethodology,
} from "~/types/csv/TypePpi";
import type {
  TypeSpBreakdown,
  TypeSpCompany,
  TypeSpMethodology,
} from "~/types/csv/TypeSp";
import type {
  TypeWcBreakdown,
  TypeWcCompany,
  TypeWcMethodology,
} from "~/types/csv/TypeWc";
import type {
  TypeWpBreakdown,
  TypeWpCompany,
  TypeWpMethodology,
} from "~/types/csv/TypeWp";
import type {
  TypeRabBreakdown,
  TypeRabCompany,
  TypeRabMethodology,
} from "~/types/csv/TypeRab";

export const isAnimalPharma = (
  dataSource?: TypeDataSourceParsed | null,
): dataSource is TypeDataSourceParsed<
  TypeApCompany,
  TypeApMethodology,
  TypeApBreakdown
> => dataSource?.content.fields.project?.fields.slug === "animal-pharma";

export const isRestaurantAntibiotics = (
  dataSource?: TypeDataSourceParsed | null,
): dataSource is TypeDataSourceParsed<
  TypeRabCompany,
  TypeRabMethodology,
  TypeRabBreakdown
> => dataSource?.content.fields.project?.fields.slug === "restaurant-antibiotics";

export const isAntibioticsStewardship = (
  dataSource?: TypeDataSourceParsed | null,
): dataSource is TypeDataSourceParsed<
  TypeAsCompany,
  TypeAsMethodology,
  TypeAsBreakdown
> =>
  dataSource?.content.fields.project?.fields.slug === "antibiotics-stewardship";

export const isProteinDiversification = (
  dataSource?: TypeDataSourceParsed | null,
): dataSource is TypeDataSourceParsed<
  TypePdCompany,
  TypePdMethodology,
  TypePdBreakdown
> =>
  dataSource?.content.fields.project?.fields.slug === "protein-diversification";

export const isProteinProducerIndex = (
  dataSource?: TypeDataSourceParsed | null,
): dataSource is TypeDataSourceParsed<
  TypePpiCompany,
  TypePpiMethodology,
  TypePpiBreakdown
> =>
  dataSource?.content.fields.project?.fields.slug === "protein-producer-index";

export const isSustainableProteins = (
  dataSource?: TypeDataSourceParsed | null,
): dataSource is TypeDataSourceParsed<
  TypeSpCompany,
  TypeSpMethodology,
  TypeSpBreakdown
> => dataSource?.content.fields.project?.fields.slug === "sustainable-proteins";

export const isWorkingConditions = (
  dataSource?: TypeDataSourceParsed | null,
): dataSource is TypeDataSourceParsed<
  TypeWcCompany,
  TypeWcMethodology,
  TypeWcBreakdown
> => dataSource?.content.fields.project?.fields.slug === "working-conditions";

export const isWastePollution = (
  dataSource?: TypeDataSourceParsed | null,
): dataSource is TypeDataSourceParsed<
  TypeWpCompany,
  TypeWpMethodology,
  TypeWpBreakdown
> => dataSource?.content.fields.project?.fields.slug === "waste-pollution";

export const isMeatSourcing = (
  dataSource?: TypeDataSourceParsed | null,
): dataSource is TypeDataSourceParsed<
  TypeMsCompany,
  TypeMsMethodology,
  TypeMsBreakdown
> => dataSource?.content.fields.project?.fields.slug === "meat-sourcing";

export const isCompanyKey = (
  key: string | undefined,
  company: TypeDataSourceParsed["company"],
): key is NonNullable<keyof typeof company> =>
  !!company && !!key && key in company;
